.addpost_wrapper {
  padding: 10px;
  margin-top: 6px !important;

  .user_img_wraper {
    width: 50px;
    height: 50px;
    border-radius: 50%;

    overflow: hidden;
    border: 2px solid #a3498b;
    float: left;
    margin: 0 3px;

    img {
      width: 100%;
      height: -webkit-fill-available;
    }
  }

  .content_wrap {
    display: flex;

    .content_txt {
      //textarea
      border-radius: 40px;
      width: 100%;
      font-size: 18px;
      color: #444;
      padding: 10px 15px;
    }

    .content_btn_link {
      padding: 0 5px;

      .text_link {
        color: #696c6f;
        font-weight: 600;
        margin-top: 5px;
        margin-bottom: 0;
        cursor: pointer;
      }

      .text_type {
        color: #777;
      }

      .text_link:hover {
        text-decoration: underline;
      }
    }
  }

  .add_gallery_wrap {
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow: hidden;

    .added_img {
      width: 25%;
      display: inline-table;
      padding: 5px;
      vertical-align: -webkit-baseline-middle;
      height: 9rem;

      i {
        // position: absolute;
        font-size: 18px;
        color: #a3498b;
        cursor: pointer;
      }

      img {
        width: 100%;
      }
    }
  }

  .actions {
    .action {
      font-size: 25px;
      cursor: pointer;
      margin: 0 3px;
      color: #444;
    }

    .active {
      color: #a3498b;
      font-size: 28px;
    }
  }
}

i.iconsminds-youtube {
  font-size: 32px;
}

.modal-dialog.modal-xl.modal-dialog-centered {
  max-width: 60%;
  box-shadow: none;
}

ol.carousel-indicators {
  bottom: -30px;
  cursor: pointer;
}

a.carousel-control-next {
  cursor: pointer;
  right: -15%;
}

a.carousel-control-prev {
  cursor: pointer;
  left: -15%;
}

.mdl_post {

  .header {
    background-color: #a3498b;
    padding-right: 16px;
    padding-left: 24px;
    color: #fff;
    display: flex;
    height: 50px;
    flex-shrink: 0;

    .name {
      flex: 1;
      margin-top: 10px;
      font-size: 27px;
    }

    .right-action {
      padding-top: 8px;
      margin-top: 2px;
      cursor: pointer;
      font-size: 20px;
      font-weight: 700;
      border-radius: 50%;
      width: 45px;
      height: 45px;
      text-align: center;
    }

    .right-action:hover {
      background-color: hsla(0, 0%, 100%, .15);
    }
  }

  .body {
    padding: 12px 24px;

    .specifications_wrap {
      display: flex;

      .right-side {
        flex: 1;
        padding: 0 5px;
      }

      .limitations_wrap {
        display: flex;

        .limitation_types {
          flex: auto;
          display: flex;
          background-color: transparent;
          color: rgba(0, 0, 0, .6);
          // box-shadow: inset 0 0 0 1px rgba(0, 0, 0, .6);
          cursor: pointer;
          padding: 3px;

          .type_text {
            flex: auto;
            text-decoration: underline;
            padding: 0 5px;
            color: #a3498b;
            font-weight: 600;
          }

          i {
            color: #a3498b;
          }
        }
      }
    }

    .user_img_wraper {
      width: 50px;
      height: 50px;
      border-radius: 50%;

      overflow: hidden;
      border: 2px solid #a3498b;
      // float: left;
      // margin: 0 3px;

      img {
        width: 100%;
        height: -webkit-fill-available;
      }
    }


    .content_text {
      border: none;
      width: 100%;
      margin: 15px 0;
      font-size: 17px;
    }







    .add_gallery_wrap {
      border: 1px solid #ddd;
      border-radius: 10px;
      overflow: hidden;

      .added_img {
        width: 25%;
        display: inline-table;
        padding: 5px;
        vertical-align: -webkit-baseline-middle;
        height: 9rem;

        i {
          // position: absolute;
          font-size: 18px;
          color: #a3498b;
          cursor: pointer;
        }


        img {
          width: 100%;
        }
      }
    }
  }


  .checks_wrap {
    padding: 1rem;

    .info {
      padding: 12px 24px;
      color: rgba(0, 0, 0, .6);
      margin: 0
    }

    .check_item {
      display: flex !important;
      padding: 12px 24px !important;
      border-top: 1px solid #ddd;
      cursor: pointer;

      .name {
        flex: 1 !important;
        font-size: 17px;
        margin: 0;
      }

      // .subname{

      // }
    }

    .check_item:hover {
      background-color: #f3f6f8;
    }
  }

  .footer {
    border-top: 1px solid #d7d7d7;
    padding: 10px 28px;
    text-align: right;

    .actions {
      flex: 1;
      text-align: left;

      .action {
        font-size: 25px;
        cursor: pointer;
        margin: 0 3px;
        color: #444;
      }

      .active {
        color: #a3498b;
        font-size: 28px;
      }
    }
  }
}

.iframe_video_wrap {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  margin-bottom: 10px;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}


.add-event-cover {
  overflow: hidden;
  margin-top: 6px !important;

  .caption {
    display: none;
    // label, i{ display: hidden; }
  }
}

.add-event-cover:hover {
  .caption {
    display: block;
    position: absolute;
    bottom: 0;
    background: #0005;
    width: 100%;
    text-align: center;
    color: #fff;
    padding: 5px;
    font-size: 25px;
    cursor: pointer;

    label {
      margin: 0;
      cursor: pointer;
    }
  }
}


.card_action {
  padding: 5px;
  padding-top: 30px;
  font-size: 20px;
  color: #F44336;

  i {
    cursor: pointer;
  }
}

.ev-card {
  // overflow: hidden;
  min-height: 15.5em;

  .img-wrapper {
    img {
      width: 100%;
      height: 19vh;
      border-top-left-radius: 0.75rem;
      border-top-right-radius: 0.75rem;
    }
  }

  .details {
    padding: 5px;
    padding-inline: 15px;

    .name {
      color: #1D1D1D;
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 0px !important;
      height: 2em;
    }

    .date {
      color: #6A717B;
      font-weight: 400;
      height: 1vw;
      margin-top: 10px;
    }
  }
}

.card-details {
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 5px;
  padding-inline: 15px;
  // overflow: hidden;

  .titles {
    // max-height: 2.3rem;
  }

  .infos {
    max-height: 3.8rem;
  }


  span.title {
    font: normal normal bold 16px/14px Nunito;
    height: 100%;
  }

  .label span {
    color: #6a717b;
    font: normal normal bold 14px/12px Nunito;
    // float: left;
    margin-right: 2px;
  }

  .extras span {
    color: #6a717b;
  }


}

span.card-badge.badge {
  text-align: right;
  // float: right;
}

.tutoring-card:hover {
  -ms-transform: scale(1.05);
  /* IE 9 */
  -webkit-transform: scale(1.05);
  /* Safari 3-8 */
  transform: scale(1.05);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2) !important;

  .badge-card.badge {
    opacity: 0.5
  }

}

// .icon-card:hover {
// -ms-transform: scale(1.5); /* IE 9 */
// -webkit-transform: scale(1.5); /* Safari 3-8 */
// transform: scale(1.5); 
// box-shadow: 0 0px 16px 0 white !important;

// }
.action-hover {

  place-self: center;
  text-align: center;
  // z-index: 1;
  margin-bottom: 5px;
}

.action-list {
  // margin-left: 1px;
  height: 100%;
  top: 0px;
  // width: 100%;
  // position: absolute
}

@media (max-width:992px) {
  .action-list {
    display: block;
    margin: auto;
  }
}

.hover-card {
  border-radius: 4%;
  z-index: 2;
}

.tutoring-card {
  // overflow: hidden;
  min-height: 15.5em;
  height: 95%;

  .img-wrapper {
    img {
      width: 100%;
      height: 19vh;
      border-top-left-radius: 0.75rem;
      border-top-right-radius: 0.75rem;
    }
  }

  // .badge-card.badge {
  //   position: absolute;
  //   top: -5px;
  //   right: 10px;
  // }
  .mask-card {
    background-color: #6A717B;
    top: 0;
    position: absolute;
    width: 101%;
    height: 101%;
    opacity: 0.4;
    padding: 1rem;
    right: 0px;
    z-index: 1;
    border-radius: 0.75rem;
    // border-top-right-radius: 0.75rem;
  }

  .icon-card {
    // background-color: #a3498b;
    // padding: 1rem;
    // border-radius: 50%;
    // color: white;
    // cursor: pointer;
    font-size: 18px;
    z-index: 2;
    vertical-align: middle;
    // opacity: .85;

  }

  // .card-text{
  // margin-top: 25px;
  // }
  .details {
    padding: 5px;
    padding-inline: 15px;

    .name {
      color: #1D1D1D;
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 0px !important;
    }

    .date {
      color: #6A717B;
      font-weight: 400;
      height: 1vw;
    }
  }
}

.group-card {
  overflow: hidden;
  min-height: 23.5em;

  .img-wrapper {
    img {
      width: 100%;
      height: 17vh;
    }
  }

  .details {
    padding: 5px;
    padding-inline: 15px;
    margin-bottom: 0px;

    .name {
      color: #1D1D1D;
      font-weight: 600;
      font-size: 16px;
      // height: 4.5vh;
      margin-bottom: 0px;
    }

    .date {
      color: #6A717B;
      font-weight: 400;
      height: 1vw;
    }
  }
}

.training-card {
  overflow: hidden;

  .img-wrapper {
    img {
      width: 100%;
      // height: 18vh;
    }
  }

  .details {
    padding: 5px;
    padding-inline: 15px;
    height: 20vh;

    .name {
      color: #1D1D1D;
      font-weight: 600;
      font-size: 16px;
      height: 4.5vh;
      margin-bottom: 0px;
    }

    .date {
      color: #6A717B;
      font-weight: 400;
      // height: 1vw;
    }

    .location {
      height: 1vw;
    }
  }
}

.back-button {
  cursor: 'pointer';
  background-color: '#A6458B';
  border-width: 0;
  color: '#fff';
  border-radius: 100;
  font-size: 28;
  position: 'absolute';
  z-index: 999;
  right: '1.5rem';
  top: '1rem';
  padding: 2;
}

.inst-preview {
  padding: 10px;
  padding-top: 0px;

  .logo {
    img {
      border-radius: 10px;
    }
  }

  .infos {
    margin: auto;
  }

  .name {
    width: 100%;
    font-size: 16px;
  }

  .date {
    font-size: 16px;

    i {
      color: #303030
    }
  }

  .address {
    font-size: 13px;
  }

  .email {
    font-size: 13px;
  }

  .phone {
    font-size: 13px;
  }

  .url {
    font-size: 13px;
  }

  .desc {
    font-size: 14px;
  }
}

.preview_details {
  padding: 10px;
  margin-top: 5px !important;

  .picture {
    border-radius: 10px;
  }

  .infos {
    margin: auto;
  }

  .name {
    width: 100%;
  }

  .date {
    font-size: 16px;

    i {
      color: #303030
    }
  }

  .address {
    font-size: 16px;
  }

  .location {
    padding: 2px;
  }
}

.job-preview {
  padding: 10px;
  margin-top: 5px;

  .picture {
    width: 100%;
    height: 100%;

    img {
      border-radius: 10px;
      width: 100%;
      height: 100%;
    }
  }

  .infos {
    padding-left: 0px !important;

    .name {
      width: 100%;
      margin-bottom: 0rem !important;
      // margin: 0px !important;
      // margin-left: 2rem !important;

    }

    .date {
      font-size: 16px;
      margin-bottom: 0px;

      i {
        color: #303030
      }

      p {
        margin-bottom: 0px;
      }
    }

    .type {
      font-size: 16px;
      margin-bottom: 0px;
      padding-left: 4px;

      i {
        color: #303030
      }

      p {
        margin-bottom: 0px;
      }
    }

    .inst-name {
      font-size: 16px;

      p {
        margin-bottom: 0px;
      }
    }

    .inst-address {
      font-size: 16px;
      padding-left: 3px;

      p {
        margin-bottom: 0px;
      }
    }

    .inst-email {
      font-size: 16px;

      p {
        margin-bottom: 0px;
      }
    }
  }


}

.job-candidate {
  height: 11rem;

  .details {
    .logo {
      height: 5rem;
      width: 5rem;
    }

    .resume {
      position: absolute;
      right: 5px;
      top: 5px;

      a {
        i {
          color: #a3498b;
          font-size: 16px;
        }
      }
    }
  }
}

.name.mb-1.card-title {}
.card-content{
  border: 1px solid rgb(146, 44, 136);
  margin-bottom: 15px;
  border-radius: 15px;
  // background-color: aliceblue;
}
.course-card {
  padding: 10px;

  .avatar {
    width: 100%;
    border-radius: 0.75rem;
  }

  .name {
    margin-bottom: 0px;
    font-weight: 600;
    font-size: 15px;
  }

  .date {
    color: #999;
  }

  .owner {
    margin: 0;
  }

  .icon {
    margin: 0;

    i {
      color: "#999"
    }
  }
}

.button.btn.btn-primary.btn-sm.btn-block {
  color: #2987a3;
}

.job-card {
  padding: 0.5rem;
  min-height: 8rem;

  .logo-container {
    text-align: -webkit-center;

    .logo {
      height: 7rem;
      width: 7rem;

      img {
        height: 100%;
        width: 100%;
        border-radius: 5px;
      }
    }
  }


  .details {
    font-weight: 400;
    font-size: 12px;
    padding-left: 1.5rem;

    @media (max-width: 1199px) and (min-width: 991px) {
      padding-left: 2rem;

    }

    .inst {
      margin-bottom: 0px;
      color: #727272;
    }

    .address {
      margin-bottom: 0px;
      color: #727272;
    }

    .name {
      margin-bottom: 0px;
      font-weight: 600;
      font-size: 15px;
      color: #2987a3;
    }

    .desc {
      margin-bottom: 0px;
    }

    .active {
      color: #207641;
      height: 1rem;
      margin-bottom: 0px;
      margin-left: -2px;
      margin-right: 2px;
    }

    .locationType {
      color: #727272;
    }

    .date {
      color: #A6458B;
      font-weight: 600;
      margin-bottom: 0;
      position: absolute;
      bottom: -6px;
    }
  }

  .button {
    margin-top: 0.5rem;
  }

  .owner {
    margin: 0;
  }

  .icon {
    margin: 0;

    i {
      color: "#999"
    }
  }
}


.select_full_width {
  width: 100% !important;
}

.session_wrap {
  padding: 0 5px;
  // border-left: 2px solid #a3498b;
  margin-bottom: 15px;

  .trash_right {
    float: right;
    border: 1px solid #bbb;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    text-align: center;
    padding-top: 5px;
    background: #fff;
    color: #e7523e;
    margin-top: 4px;
    cursor: pointer;
    /* right: -10px; */
    /* top: -5px; */
    z-index: 999;
    /* position: absolute; */
    margin-right: -25px;
  }
}
.border-top-bottom{
  border-top: 1px solid  #cfcfcf  !important;
  
  // border-bottom: 1px solid black  !important;
}
.tree-form-btn{
  white-space: normal;
  padding-left: 5px !important;
  text-align: left;
  color: black !important;
  font-weight: 700;
  text-decoration: none !important;
  width: 100%;
  background-color: #f3f3f3;
  border-radius: 0 !important;
  @media (max-width: 1350px) {
    font-size: 10px !important;
  }
}
.tree-form-btn:hover {
  text-decoration: none !important;
}
.tree-form-sub-btn{
  padding-left: 5px !important;
  white-space: normal;
  text-align: left;
  color: black !important;
  font-weight: 600;
  text-decoration: none !important;
  width: 100%;
  background-color: #f3f3f3;
  border-radius: 0 !important;
}
.tree-form-sub-btn:hover {
  text-decoration: none !important;
}


.chapter-wraper {
  border: 1px solid #bbb;
  border-radius: 5px;
  padding: 10px;
  min-height: 40px;
  margin-top: 30px;

  .chapter-head {
    margin-top: -30px;
    margin-bottom: 50px;

    .arrow-toggle {
      float: left;
      border: 1px solid #bbb;
      width: 30px;
      height: 30px;
      border-radius: 15px;
      text-align: center;
      padding-top: 7px;
      background: #fff;
      color: #444;
      margin-top: 4px;
      cursor: pointer;
    }

    .chapter-name {
      width: 50%;
      float: left;
      padding: 8px;
      border-radius: 5px;
      margin: 0 5px;
    }

    .chapter-trash {
      float: right;
      border: 1px solid #bbb;
      width: 30px;
      height: 30px;
      border-radius: 15px;
      text-align: center;
      padding-top: 5px;
      background: #fff;
      color: #dc3545;
      margin-top: 4px;
      cursor: pointer;
    }
    .chapter-trash-course {
      float: right;
      font-size: 1.25rem;
      // border: 1px solid #bbb;
      width: 27px;
      height: 30px;
      // border-radius: 15px;
      text-align: center;
      padding-top: 5px;
      background: #fff;
      color: #dc3545;
      cursor: pointer;
    }
    .chapter-add-course {
      float: right;
      font-size: 1.25rem;
      // border: 1px solid #bbb;
      width: 24px;
      height: 30px;
      // border-radius: 15px;
      text-align: center;
      padding-top: 5px;
      background: #fff;
      color: #17a2b8;
      cursor: pointer;
    }
  }

  .chapter-body {
    padding: 5px 0;
  }

  .chapter-footer {
    text-align: right;
  }
}

.trash_inst {
  float: right;
  margin: 5px;
  margin-top: -30px;
  cursor: pointer;
  color: rgb(244, 67, 54);
  font-size: 15px;
}

.add_img_wrap {
  overflow: hidden;
  border-radius: 15px !important;
  border: 1px solid #ddd;
  height: fit-content;

}

.add_img_wrap_header {
  height: 7.15rem;
}

.add_img_wrap_profile {

  float: left;
  left: 12px;
  bottom: 38px;
  width: 122px;
  height: 122px;
  margin-left: 20px;
  margin-top: -80px;
}

.profile_img_cover {

  width: 100%;
  min-height: 250px;
  margin-top: 1.5%
}

.add_img_wrap .caption {
  position: absolute;
  bottom: 0;
  background: #0005;
  width: 100%;
  text-align: center;
  color: #fff;
  padding: 5px;
  font-size: 1.2em;
  cursor: pointer;
}

.add_img_wrap .caption label {
  margin: 0;
  cursor: pointer;
  width: -webkit-fill-available;
}

.add_img_wrap:hover .caption {
  display: block;
}












.iframe_rocket {
  position: absolute;
  width: 100%;
  height: 100%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 112.1%;
    margin-top: 60px;

  }
}



.comments-wrapper {
  max-height: 200px !important;
  overflow-y: auto;

  &::-webkit-scrollbar {
    border-radius: 10px;
    width: 8px;
  }
}

.comment-container.input-group {
  .form-control {
    font-size: 0.8rem;
    height: initial;
    padding: 0.5rem 0.75rem;
    line-height: 1;
  }

  .btn.btn-primary {
    padding: 0.5rem 1.25rem 0.5rem 1.25rem;
    color: #2987a3;
  }
}

.post-card {
  box-shadow: none !important;
  border: 1px solid #ddd !important;
  border-radius: 10px !important;

  .card-body {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 10px;
    padding: 10px !important;
  }
}

.post-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2) !important;
}

.post_owner {
  height: 50px !important;
  width: 50px !important;
}







.tree::after {
  content: '';
  display: block;
  clear: left;
}

.tree div {
  clear: left;
}

input[type="checkbox"].tree-checkbox {
  display: none;
  // transition-duration: 0.5s;
  // transition-property: transform;
}

label.tree-child,
a.tree-subs {
  margin-bottom: 3px;
  margin-top: 3px;

  display: block;
  clear: left;
  position: relative;
  white-space: break-spaces;

  cursor: pointer;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-left: 5px;
  // transition-duration: 0.5s;
  // transition-property: transform;

}
a,span,p:first-letter {
  // text-transform: capitalize;
}
// label.tree-child::before, a.tree-subs::before {
//   // display: block;
//   // position: absolute;
//   // // top: 6px;
//   // // left: -20px;
.progress-text{
  font-weight: bolder;
  padding-left: 0px;
  font-size: 14px;
  color:rgb(163, 73, 139);
}
.label-file {
  cursor: pointer;
  color:rgb(163, 73, 139);
  font-weight: bold;
}
.label-file:hover {
  color:rgb(163, 73, 139);
}

// et on masque le input
.input-file {
  display: none;
}
// }
.quiz-btn {
  width: 100%;
  padding: 0.2rem !important;
  font-size: 12px;
  // border: 1px solid rgb(146, 44, 136);
  margin-bottom: 10px;
  margin-top: 5px;
}
label.tree-child::before {
  content: '\232A';
  /* closed folder */
  color: rgb(163, 73, 139);
  font-size: 15px;
  vertical-align: middle;
  font-weight: bolder;
  margin-bottom: 7px;
    margin-top: 7px;
  
}
input.tree-checkbox:checked+label.tree-child {
  color: rgb(146, 44, 136);

}
input.tree-checkbox:checked+label.tree-child::before {
  content: '\232A';
  /* open folder */
  color: rgb(163, 73, 139);
  font-size: 15px;
  vertical-align: middle;
  display: inline-block;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  font-weight: bolder;
  margin-right: 7px;
  // margin-left: 7px;
  margin-top: 7px;
  transition-duration: 0.1s;
  transition-property: transform;
}

// a.tree-subs::before {
//   content: '\f068'; /* dash */
// }



.sub {
  display: none;
  float: left;
  margin-left: 10px;
}

input.tree-checkbox:checked~.sub {
  display: block;
}



// form.tree-form ::before {
// 	content: '';
// 	position: absolute;
// 	top: -20px;
// 	left: 15px;
// 	width: 10px;
// 	height: 10px;
// 	border-radius: 50%;
//   background-color: #adadae;
// 	box-shadow:
//     20px 0 0 #adadae,
//     40px 0 0 #adadae;
// }



// div.tree {
//   padding: 20px 0;
// }

// div.tree::after {
//   content: '';
//   display: block;
//   clear: left;
// }

// div.tree {
//   clear: left;
// }

// div.tree input[type="checkbox"] {
//   display:none
// }

// div.tree label, a {
//   display: block;
//   float: left;
//   clear: left;
//   position: relative;
//   margin-left: 25px;
//   padding: 5px;
//   border-radius: 5px;
//   color: #5c5d5e;
//   text-decoration: none;
//   cursor: pointer;
// }

// div.tree label::before, a::before {
//   display: block;
//   position: absolute;
//   top: 6px;
//   left: -20px;
// }

// div.tree label::before {
//   content: '\002B'; /* closed folder */
// }

// div.tree input:checked + label::before {
//   content: '\2212'; /* open folder */
// }

// div.tree a::before {
//   content: '\f068'; /* dash */
// }



// div.sub {
//   display: none;
//   float: left;
//   margin-left: 30px;
// }

// div.tree input:checked ~ .sub {
//   display: block;
// }

// div.tree input[type="reset"] {
//   display: block;
//   width: 100%;
//   padding: 10px;
//   border: none;
//   border-radius: 10px;
//   color: #e8ebed;
//   background-color: #6b7c87;
//   font-family: inherit;
//   font-size: .9em;
//   cursor: pointer;
//   -webkit-appearance: none;
//   -moz-appearance: none;
// }

// div.tree input[type="reset"]:focus {
//   outline: none;
//   box-shadow: 0 0 0 4px #b9c3c9;
// }